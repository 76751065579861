/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as React from "react";
import Grid from "@mui/material/Grid";
import { useTerminalContext } from "../context/terminalContext.tsx";
import { Hostnames, checkValidResponse } from "./utils.tsx";

interface PromptProps {
  clear?: boolean;
}
const Prompt: React.FC<PromptProps> = ({ clear }) => {
  const [inputEnabled, toggleInputEnabled] = React.useState<boolean>(true);
  const [currentCommand, setCurrentCommand] = React.useState<string>("");
  const { hostname, promptSubmitted, clearCommand } = useTerminalContext();
  const [result, setResult] = React.useState<React.ReactElement | null>(null);
  const updateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentCommand(e.target.value);
  };

  const submit = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      toggleInputEnabled(false);
      if (currentCommand === "clear") {
        clearCommand();
      } else {
        setResult(checkValidResponse(currentCommand));
        promptSubmitted();
      }
    }
  };

  React.useEffect(() => {
    toggleInputEnabled(true);
    setCurrentCommand("");
    setResult(null);
  }, [clear]);

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const [gridSize, setGridSize] = React.useState<number>(1);
  React.useEffect(() => {
    if (width >= 2513) {
      setGridSize(1);
    } else if (width >= 1735) {
      setGridSize(1.3);
    } else {
      setGridSize(1.5);
    }
  }, [width]);

  return (
    <>
      <Grid container xs={12} spacing={0}>
        <Grid
          item
          //xs={gridSize}
          css={css`
            @media (min-width: 440px) {
              min-height: 100%;
            }
          `}
        >
          <span
            css={css`
              background-color: rgba(56, 81, 112, 0.5);
              padding: 2px 5px 0px 5px;
              font-family: "Fira Code";
              font-size: 12px;
              font-weight: semi-bold;
              color: #dbdfea;
            `}
          >
            root@{hostname} :: ~
          </span>
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <input
            disabled={!inputEnabled}
            autoFocus
            onChange={updateChange}
            onKeyDown={submit}
            css={css`
              border: none;
              min-width: 100%;
              background-color: transparent;
              color: white;
              outline: none;

              font-family: "Fira Code";
              font-size: 12px;
              box-shadow: none;
              border-color: transparent;
            `}
          />
        </Grid>
      </Grid>
      {result ? (
        <Grid container xs={12}>
          {result}
        </Grid>
      ) : null}
    </>
  );
};

export { Prompt };
