/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import * as React from "react";
import Grid from "@mui/material/Grid";
import { Contact } from "./responses/Contact";
import { Help } from "./responses/Help";
import { Projects } from "./responses/Project";
import { About } from "./responses/About";
import { Uptime } from "./responses/Uptime";

export enum Hostnames {
  Moltres = "moltres",
  Zapdos = "zapdos",
  Articuno = "articuno",
}
const invalid: string = "zsh: command not found: ";

const hosts: Hostnames[] = [
  Hostnames.Articuno,
  Hostnames.Moltres,
  Hostnames.Zapdos,
];

export const checkValidResponse = (prompt: string): React.ReactElement => {
  switch (prompt) {
    case "uptime":
      return Uptime;
    case "whoami":
      return About;
    case "projects":
      return Projects;
    case "contact":
      return Contact;
    case "help":
      return Help;
    default:
      return (
        <>
          <Grid item xs={12}>
            <span className="terminalResponse">
              {invalid} {prompt}
            </span>
          </Grid>

          <Grid item xs={12}>
            <span className="terminalResponse">
              Use `help` to find out more.
            </span>
          </Grid>
        </>
      );
  }
};

export const getHostName = (): Hostnames => {
  return hosts[Math.floor(Math.random() * hosts.length)];
};
