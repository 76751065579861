/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as React from "react";
import { Grid } from "@mui/material";
type contactMapType = {
  [id: string]: string;
};
const contactMap: contactMapType = {
  LinkedIn: "https://www.linkedin.com/in/jingkaitan/",
  Github: "https://github.com/xlanor",
};
const Contact: React.ReactElement = (
  <>
    {Object.keys(contactMap).map((key: string) => {
      return (
        <>
          <Grid xs={12}>
            <span className="terminalResponse">
              <a
                css={css`
                  color: grey;
                `}
                href={contactMap[key]}
                target="_blank"
              >
                {key}
              </a>
            </span>
          </Grid>
        </>
      );
    })}
  </>
);

export { Contact };
