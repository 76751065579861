import * as React from "react";
import { Grid } from "@mui/material";
const commands: string[] = ["uptime", "whoami", "projects", "contact", "clear"];
const Help: React.ReactElement = (
  <>
    <Grid xs={12}>
      <span className="terminalResponse">
        The following commands are available:
      </span>
    </Grid>
    {commands.map((c: string): React.ReactElement => {
      return (
        <Grid xs={12}>
          <span className="terminalResponse">{c}</span>
        </Grid>
      );
    })}
  </>
);

export { Help };
