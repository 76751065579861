import * as React from "react";

import { Grid } from "@mui/material";

const About: React.ReactElement = (
  <>
    <Grid xs={12}>
      <span className="terminalResponse">
        Hi, my name is <u>Jingkai</u>
      </span>
    </Grid>
    <Grid xs={12}>
      <span className="terminalResponse">
        I'm currently a Senior Software Engineer working in London 🇬🇧.
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        Previously, I worked in web development as a Full Stack Developer
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        then as a Senior Devops Engineer running a small team in Singapore 🇸🇬
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        In my free time, I'm currently pursuing my MSc from Gatech, and
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        learning photography in my free time.
      </span>
    </Grid>
  </>
);

export { About };
