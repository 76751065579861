import * as React from "react";
import { Grid } from "@mui/material";

const Uptime = (
  <>
    <Grid xs={12}>
      <span className="terminalResponse">
        {new Date().toLocaleTimeString()} up 160195 days, 2 users, load average:
        0.16, 0.04, 0.03
      </span>
    </Grid>
  </>
);

export { Uptime };
