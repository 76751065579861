import * as React from "react";

import { Grid } from "@mui/material";
const Projects: React.ReactElement = (
  <>
    <Grid xs={12}>
      <span className="terminalResponse">
        <u>Linux Mirror</u>
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        I currently run a linux mirror serving the Asia Pacific region for
        several distros, such as Alma, Rocky, Arch, and Endeavour.
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        <u>Homelabbing</u>
      </span>
    </Grid>

    <Grid xs={12}>
      <span className="terminalResponse">
        I run a homelab back in Singapore across several physical hosts. It
        allows me to run public services such as my mirror, as well as serve as
        a playground for me to improve my infrastructure skills.
      </span>
    </Grid>
    <Grid xs={12}>
      <span className="terminalResponse">
        <u>OSS Contributions</u>
      </span>
    </Grid>
    <Grid xs={12}>
      <span className="terminalResponse">
        Whenever I'm free, I try to look for OSS projects where I can contribute
        fixes upstream. ORY Oathkeeper, Keto, Apache Airflow, Mattermost, are
        amongst some of the upstream repositories that I have contributed fixes
        to
      </span>
    </Grid>
  </>
);

export { Projects };
